import request from './request'


//新增
export function insert(object) {
  return request.post('/game/insert', object)
}

//分页查询
export function GetPageAll(object) {
  return request.get('/game/GetPageAll', object)
}

//修改
export function update(object) {
  return request.post('/game/update', object)
}

//删除
export function deleteById(id) {
  return request.get('/game/delete', id)
}

//获取所有
export function queryAll() {
  return request.post('/game/queryAll')
}
