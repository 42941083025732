//导入其他api文件
import * as role from './role'
import * as carousel from './carousel'
import * as user from './user'
import * as game from './game'
import * as product from './product'
import * as notice from './notice'
import * as focus from './focus'
import * as playwith from './playwith'
import * as order from './order'
import * as carrecord from './carrecord'
import * as message from './message'


export default {
    user,
    role,
    carousel,
    game,
    product,
    notice,
    focus,
    playwith,
    order,
    carrecord,
    message,
}
