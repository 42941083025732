import request from './request'


//添加用户
export function register(object) {
	return request.post('/user/register', object)
}

//登录
export function login(object) {
	return request.get('/user/login', object)
}

//获取用户分页列表
export function GetPageAll(object) {
	return request.get('/user/GetPageAll', object)
}

//修改用户信息
export function updateUser(object) {
	return request.post('/user/updateUser', object)
}

//删除用户信息
export function deleteUser(id) {
	return request.get('/user/deleteUser', id)
}

//禁用启用用户状态
export function disableUser(id) {
	return request.get('/user/disableUser', id)
}

//删除头像
export function fileDelete(url) {
	return request.get('/file/delete', url)
}


//获取用户所有信息
export function getPersonInfo(id) {
	return request.get('/user/getPersonInfo', id)
}

//修改密码
export function updatePwd(object){
	return request.post('/user/updatePwd',object)
}

//获取角色列表
export function getEchartsUserData1() {
	return request.post('/user/getEchartsUserData1')
}

//获取角色对应数量列表
export function getEchartsUserData2() {
	return request.post('/user/getEchartsUserData2')
}

//获取用户列表
export function queryAll() {
	return request.post('/user/queryAll')
}


//获取对应名称的 用户列表
export function queryAllByNameUser(name) {
	return request.get('/user/queryAllByNameUser', name)
}

//获取对应用户的个人信息以及 所有陪玩列表
export function queryAllInfoById(id) {
	return request.get('/user/queryAllInfoById', id)
}
