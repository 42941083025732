import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/plugins/element'//按需引入 element-ui
//引入图标和字体库
import './assets/iconfont/iconfont.css'
import api from './api'// 所有的 api文件 
import * as echarts from 'echarts'
import VueParticles from 'vue-particles'
import 'highlight.js/styles/monokai-sublime.css'

Vue.use(VueParticles)

Vue.prototype.$api = api//自定义axios封装位置
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue({})  //创建一个vue实例,将$bus 注册到vue的prototype上 用于兄弟传值
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  api,// 后续使用只需要在每一个vue文件中使用this.$api.{对应.js导出文件名称}.method即可
  render: h => h(App)
}).$mount('#app')


