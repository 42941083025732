import request from './request'


//新增
export function insert(object) {
  return request.post('/focus/insert', object)
}

//分页查询
export function GetPageAll(object) {
  return request.get('/focus/GetPageAll', object)
}

//修改
export function update(object) {
  return request.post('/focus/update', object)
}

//删除
export function deleteById(id) {
  return request.get('/focus/deleteById', id)
}

//  用户是否关注了该用户
export function isStatusByUidAndFuid(object) {
  return request.get('/focus/isStatusByUidAndFuid', object)
}

// 根据 关注人 关注用户删除记录
export function deleteByUidAndFuid(object) {
  return request.get('/focus/deleteByUidAndFuid', object)
}

