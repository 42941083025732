import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: null
  },
  getters: {
  },
  mutations: {
    saveUserInfo(state, data) {
      console.log(data);
      state.id = data
    }
  },
  actions: {
  },
  modules: {
  }
})
