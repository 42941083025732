// HTTP 请求工具
// import {encode, decode} from 'js-base64'
import { Message, Loading } from "element-ui";
import router from "@/router";
import axios from "axios";


// import {Encrypt, Decrypt} from '@/utils/aesUtil'

// 设置服务器基础地址
// axios.defaults.baseURL = "/api";
// axios.defaults.baseURL = 'http://localhost:9090/api'
// axios.defaults.baseURL = 'http://47.92.229.155:9090/api'
axios.defaults.baseURL = '/api'

// 设置默认头信息
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 跨域请求设置：是否携带cookie
axios.defaults.withCredentials = false

// 请求超时时间
axios.defaults.timeout = 30000;

// let loading

// request 请求拦截器
axios.interceptors.request.use(
    request => {
        // loading = Loading.service({
        //     lock: true,
        //     text: "加载数据中~~~",
        //     spinner: "el-icon-loading",
        //     background: "rgba(0, 0, 0, 0.7)",
        //   });
        // if(request.method=="post" || request.method=="put"){
        //     request.data = {encrypted: Encrypt(request.data)}
        // }
        // const token = JSON.parse(localStorage.getItem('token'));
        // console.log(token);
        // token && (request.headers.Authorization = token);
        // console.log(request.headers.Authorization);
        return request;
    },
    error => {
        return Promise.error(error);
    }
);

// response 响应拦截器
axios.interceptors.response.use(
    response => {
        // loading.close();
        if (response.status === 200) {
            // response.data = JSON.parse(Decrypt(response.data.encrypted));
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务状态码不是200的情况
    error => {
        // loading.close();
        if (error.response.status) {
            switch (error.response.status) {
                // 302 未登录
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 302:
                    Message.error("登录失效，请重新登录！");
                    router.replace({
                        path: "/login",
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    localStorage.removeItem("token");
                    break;
                case 400:
                    Message.error("错误码400请检查请求体信息");
                    break;
                case 400:
                    Message.error("GET请求参数过大,HTTP错误码414 ");
                    break;
                default:
                    Message.error("未知异常,请联系管理员！");
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);



export default {

    /**
     * get方法对应get请求
     * @param {String}url 请求地址
     * @param {Object}params携带参数
     * @returns {Promise}
     */
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    /**
     * post方法对应post请求
     * @param {String} url 请求地址
     * @param {Object} params 携带参数
     * @returns {Promise}
     */
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, JSON.stringify(params), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    put(url, params) {
        return new Promise((resolve, reject) => {
            axios.put(url, JSON.stringify(params), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    delete(url, params) {
        return new Promise((resolve, reject) => {
            axios.delete(url, JSON.stringify(params), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    },
    download(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, JSON.stringify(params), { headers: { 'Content-Type': 'blob' } })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data);
                });
        });
    }
};
