import request from './request'

//发送消息
export function insert(object) {
	return request.post('/message/insert', object)
}

//获取消息列表
export function getMessageList(uid) {
	return request.get('/message/getMessageList', uid)
}


//获取实时消息记录
export function getMessage(object) {
	return request.get('/message/getMessage', object)
}

//删除聊天记录
export function deleteById(mid) {
	return request.get('/message/deleteById', mid)
}

