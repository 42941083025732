<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      // this.$store.replaceState替换 store 的根状态，仅用状态合并或时光旅行调试。
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store")))
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
html {
  padding: 0;
  margin: 0;
}
</style>
