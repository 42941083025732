import request from './request'

//分页查询
export function GetPageAll(object){
	return request.get('/role/GetPageAll',object)
}


//新增角色
export function insert(object){
	return request.get('/role/insert',object)
}


//修改角色
export function update(object){
	return request.get('/role/update',object)
}

//删除角色
export function deleteById(object){
	return request.get('/role/deleteById',object)
}

//获取除超级管理员之外的角色列表
export function queryAllNotAdministrator(){
	return request.post('/role/queryAllNotAdministrator')
}
