import request from './request'


//新增
export function insert(object){
	return request.post('/carousel/insert',object)
}

//分页查询
export function GetPageAll(object){
	return request.get('/carousel/GetPageAll',object)
}

//修改
export function update(object){
	return request.post('/carousel/update',object)
}

//删除
export function deleteById(cid){
	return request.get('/carousel/delete',cid)
}

//获取所有轮播
export function queryAll(){
	return request.post('/carousel/queryAll')
}
