import request from './request'



//===========================商品订单=====================================


//分页查询
export function GetPageAll(object) {
  return request.get('/orderProduct/GetPageAll', object)
}

//分页查询 根据用户id获取订单列表
export function GetPageAllByUid(object) {
  return request.get('/orderProduct/GetPageAllByUid', object)
}

//新增
export function insert(object) {
  return request.post('/orderProduct/insert', object)
}

//修改  订单付款
export function update_pay(id) {
  return request.get('/orderProduct/update/pay', id)
}

//修改  订单发货
export function update_delive(id) {
  return request.get('/orderProduct/update/delive', id)
}

//修改  订单确认
export function update_verify(id) {
  return request.get('/orderProduct/update/verify', id)
}

//修改  订单关闭
export function update_close(id) {
  return request.get('/orderProduct/update/close', id)
}

//删除
export function deleteById(id) {
  return request.get('/orderProduct/delete', id)
}

//分页获取个人卖出订单  待接单和交易完成
export function GetPageAllByUidAndStatusProduct(object) {
  return request.get('/orderProduct/GetPageAllByUidAndStatus', object)
}

//=======================陪玩订单=================================


export function GetPageAll2(object) {
  return request.get('/orderPlaywith/GetPageAll', object)
}

//分页查询 根据 用户id
export function GetPageAllByUid2(object) {
  return request.get('/orderPlaywith/GetPageAllByUid', object)
}

//新增
export function insert2(object) {
  return request.post('/orderPlaywith/insert', object)
}

//修改  订单付款
export function update_pay2(id) {
  return request.get('/orderPlaywith/update/pay', id)
}

//修改  订单接单
export function update_delive2(id) {
  return request.get('/orderPlaywith/update/delive', id)
}

//修改  订单接单
export function update_verify2(id) {
  return request.get('/orderPlaywith/update/verify', id)
}

//修改  订单关闭
export function update_close2(id) {
  return request.get('/orderPlaywith/update/close', id)
}

//删除
export function deleteById2(object) {
  return request.get('/orderPlaywith/delete', object)
}

//分页获取个人卖出订单  待接单和交易完成
export function GetPageAllByPidAndStatus(object) {
  return request.get('/orderPlaywith/GetPageAllByPidAndStatus', object)
}
