import request from './request'


//新增
export function insert(object) {
  return request.post('/carrecord/insert', object)
}

//分页查询
export function GetPageAll(object) {
  return request.get('/carrecord/GetPageAll', object)
}

//修改
export function update(object) {
  return request.post('/carrecord/update', object)
}

//删除
export function deleteById(carid) {
  return request.get('/carrecord/deleteById', carid)
}

//获取对应用户的购物车商品
export function getgoodsData(uid) {
  return request.get('/carrecord/getgoodsData', uid)
}

// 用户购物车是否存在该商品
export function isStatusByUidAndPid(object) {
  return request.get('/carrecord/isStatusByUidAndPid', object)
}

// 通过 用户 id 和商品id 删除 记录
export function deleteByUidAndPid(object) {
  return request.get('/carrecord/deleteByUidAndPid', object)
}
