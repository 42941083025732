import request from './request'



//新增
export function addProduct(object) {
  return request.post('/product/addProduct', object)
}

//分页查询
export function GetPageAll(object) {
  return request.get('/product/GetPageAll', object)
}

//修改
export function update(object) {
  return request.post('/product/updateProduct', object)
}

//删除
export function deleteById(object) {
  return request.get('/product/deleteProduct', object)
}

//禁用 启动
export function diasbleProduct(id) {
  return request.get('/product/diasbleProduct', id)
}

//删除文件
export function deleteImage(url) {
  return request.get('/file/delete', url)
}

//获取所有
export function queryAll() {
  return request.post('/product/queryAll')
}

//根据游戏类别 分类获取商品
export function GetPageAllByGid(object) {
  return request.get('/product/GetPageAllByGid', object)
}

//获取单个详情信息
export function querySingleById(id) {
  return request.get('/product/querySingleById', id)
}

//获取各类商品的数量 使用给 echarts 统计图
export function getDataProductNumber() {
  return request.post('/product/getDataProductNumber')
}

//分页查询 根据用户发布的商品查询 看不到管理员隐藏的商品
export function GetPageAllByUid(object) {
  return request.get('/product/GetPageAllByUid', object)
}

//分页查询 根据用户发布的商品查询  看不到管理员隐藏的商品
export function GetPageAllByHome(object) {
  return request.get('/product/GetPageAllByHome', object)
}

