import request from './request'


//分页查询
export function GetPageAll(object) {
	return request.get('/notice/GetPageAll', object)
}


//新增
export function insert(object) {
	return request.post('/notice/insert', object)
}


//修改
export function update(object) {
	return request.post('/notice/update', object)
}

//删除
export function deleteById(object) {
	return request.get('/notice/delete', object)
}

//获取随机数量的数据
export function randomData(number) {
	return request.get('/notice/randomData', number)
}

//获取单个精确数据
export function queryByIid(iid) {
	return request.get('/notice/queryByIid', iid)
}