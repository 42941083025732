import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: "login", component: () => import('@/views/Login/index'), meta: { title: '登录' } },
  { path: '/register', name: "register", component: () => import('@/views/Register/index'), meta: { title: '注册' } },
  { path: '/home', name: "home", component: () => import('@/views/Home/index'), meta: { title: '游伴侣' } },
  { path: '/category', name: "category", component: () => import('@/views/Category/index'), meta: { title: '陪玩分区' } },
  { path: '/noticeView', name: "noticeView", component: () => import('@/views/Notice/index'), meta: { title: '伴侣资讯' } },
  { path: '/noticeDetail', name: "noticeDetail", component: () => import('@/views/Notice/detail'), meta: { title: '资讯内容' } },
  { path: '/shop', name: "shop", component: () => import('@/views/Shop/index'), meta: { title: '游戏商城' } },
  { path: '/playwithView', name: "playwithView", component: () => import('@/views/Playwith/index'), meta: { title: '陪玩详情' } },
  { path: '/productView', name: "productView", component: () => import('@/views/Product/index'), meta: { title: '商品详情' } },
  { path: '/person', name: "person", component: () => import('@/views/Person/index'), meta: { title: '个人中心' } },
  { path: '/userFocus', name: "userFocus", component: () => import('@/views/Person/userFocus'), meta: { title: '我的订单' } },
  { path: '/myorder', name: "myorder", component: () => import('@/views/Order/index'), meta: { title: '我的订单' } },
  { path: '/myCarrecord', name: "myCarrecord", component: () => import('@/views/Carrecord/index'), meta: { title: '购物车' } },
  { path: '/checkout', name: "checkout", component: () => import('@/views/Carrecord/checkout'), meta: { title: '购物清单' } },
  { path: '/personUpdatePwd', name: "personUpdatePwd", component: () => import('@/views/Person/personUpdatePwd'), meta: { title: '消息中心' } },
  { path: '/result', name: "result", component: () => import('@/views/Carrecord/Result'), meta: { title: '下单成功' } },
  { path: '/communication', name: "communication", component: () => import('@/views/Communication/index'), meta: { title: '消息中心' } },
  { path: '/mysoldorder', name: "mysoldorder", component: () => import('@/views/Person/mysoldorder'), meta: { title: '我卖出的' } },
  ,
  {
    path: '/backhome', name: "backhome", component: () => import('../back/common/Home'), meta: { title: '后台首页' }, redirect: '/backindex', children: [
      { path: '/backindex', name: 'backindex', component: () => import('../back/Index/index'), meta: { title: '后台主页' }, },
      { path: '/private', name: 'private', component: () => import('../back/Person/index'), meta: { title: '个人资料' }, },
      { path: '/updatepwd', name: 'updatepwd', component: () => import('../back/Person/Updatepwd'), meta: { title: '修改密码' }, },
      { path: '/user', name: 'user', component: () => import('../back/User/index'), meta: { title: '用户管理' }, },
      { path: '/carousel', name: 'carousel', component: () => import('../back/Carousel/index'), meta: { title: '轮播图管理' }, },
      { path: '/notice', name: 'notice', component: () => import('../back/Notice/index'), meta: { title: '资讯管理' }, },
      { path: '/role', name: 'role', component: () => import('../back/Role/index'), meta: { title: '角色管理' }, },
      { path: '/game', name: 'game', component: () => import('../back/Game/index'), meta: { title: '游戏类别管理' }, },
      { path: '/product', name: 'product', component: () => import('../back/Product/index'), meta: { title: '商品管理' }, },
      { path: '/focus', name: 'focus', component: () => import('../back/Focus/index'), meta: { title: '关注记录' }, },
      { path: '/playwith', name: 'playwith', component: () => import('../back/Playwith/index'), meta: { title: '陪陪管理' }, },
      { path: '/orderProduct', name: 'orderProduct', component: () => import('../back/OrderProduct/index'), meta: { title: '商品订单' }, },
      { path: '/orderPlaywith', name: 'orderPlaywith', component: () => import('../back/OrderPlaywith/index'), meta: { title: '陪玩订单' }, },
      { path: '/carrecord', name: "carrecord", component: () => import('../back/Carrecord/index'), meta: { title: '购物车记录' } }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const whiteList = ['/login', '/register', '/']

//路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) { //如果设置标题，拦截后设置标题
    document.title = to.meta.title
  }
  let token = sessionStorage.getItem("token")//查看登录token
  if (whiteList.indexOf(to.path) !== -1) {
    // 如果在白名单中，则直接放行
    next()
  } else if (to.path == '/') {
    if (token) {
      next({ path: '/home' })
    }
  } else if (token == null) {
    Vue.prototype.$message({
      type: "error",
      message: "请先登录"
    })
    next({ path: '/' })
  }
  next()
})


export default router
