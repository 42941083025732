import request from './request'


//新增
export function insert(object) {
  return request.post('/playwith/insert', object)
}

//分页查询
export function GetPageAll(object) {
  return request.get('/playwith/GetPageAll', object)
}

//修改
export function update(object) {
  return request.post('/playwith/update', object)
}

//删除
export function deleteById(id) {
  return request.get('/playwith/delete', id)
}

//获取所有
export function queryAll() {
  return request.post('/playwith/queryAll')
}

//随机推荐
export function randomData(number) {
  return request.get('/playwith/randomData', number)
}

//获取单个详情信息
export function querySingleById(id) {
  return request.get('/playwith/querySingleById', id)
}